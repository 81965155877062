/* カードアニメーション */
.card-animation {
    transform: translateY(0) scale(1);
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    transition: transform 0.3s ease, box-shadow 0.3s ease, filter 0.3s ease;
  }
  
  .card-animation:hover {
    transform: translateY(-8px) scale(1.02);
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.2), 0 10px 10px -5px rgba(0, 0, 0, 0.1);
    filter: brightness(1.1);
  }
  
  /* モーダル関連のアニメーション */
  .modal-enter {
    opacity: 0;
  }
  
  .modal-enter-active {
    opacity: 1;
    transition: opacity 300ms;
  }
  
  .modal-exit {
    opacity: 1;
  }
  
  .modal-exit-active {
    opacity: 0;
    transition: opacity 300ms;
  }
  
  /* 画像ズーム効果 */
  .zoom-image {
    transform: scale(1);
    transition: transform 0.5s ease-in-out, filter 0.5s ease;
    filter: brightness(0.95);
  }
  
  .card-animation:hover .zoom-image {
    transform: scale(1.05);
    filter: brightness(1.05);
  }
  
  /* モーダル内の画像拡大アニメーション */
  @keyframes modalImageEnter {
    from {
      opacity: 0;
      transform: scale(0.95);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  .modal-image {
    animation: modalImageEnter 0.3s ease-out forwards;
  }

  /* 背景アニメーション */
  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  .animated-gradient {
    animation: gradient 15s ease infinite;
    background-size: 400% 400%;
  }
  
  /* 浮遊する粒子のアニメーション */
  @keyframes float {
    0% {
      transform: translateY(0px) translateX(0px);
    }
    50% {
      transform: translateY(-20px) translateX(10px);
    }
    100% {
      transform: translateY(0px) translateX(0px);
    }
  }
  
  @keyframes float-alt {
    0% {
      transform: translateY(0px) translateX(0px) scale(1);
    }
    33% {
      transform: translateY(-15px) translateX(15px) scale(1.1);
    }
    66% {
      transform: translateY(10px) translateX(-10px) scale(0.9);
    }
    100% {
      transform: translateY(0px) translateX(0px) scale(1);
    }
  }
  
  @keyframes pulse-subtle {
    0% {
      opacity: var(--base-opacity, 0.2);
    }
    50% {
      opacity: calc(var(--base-opacity, 0.2) * 1.5);
    }
    100% {
      opacity: var(--base-opacity, 0.2);
    }
  }
  
  .floating {
    animation: float 10s ease-in-out infinite;
    animation-delay: calc(var(--delay, 0s));
  }
  
  .floating-delayed {
    animation: float-alt 12s ease-in-out infinite;
    animation-delay: calc(var(--delay, 0s));
  }
  
  .floating, .floating-delayed {
    animation-name: float, pulse-subtle;
    animation-duration: 10s, 5s;
    animation-timing-function: ease-in-out, ease;
    animation-iteration-count: infinite, infinite;
    --base-opacity: 0.15;
  }
  
  @tailwind base;
  @tailwind components;
  @tailwind utilities;
  
  @layer utilities {
    .animation-delay-500 {
      animation-delay: 0.5s;
    }
    
    .bg-blur-backdrop {
      backdrop-filter: blur(8px);
      -webkit-backdrop-filter: blur(8px);
    }
  }
  
  /* カスタムフォント設定 */
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');
  
  @layer base {
    html {
      font-family: 'Inter', system-ui, sans-serif;
    }
    
    body {
      @apply antialiased text-gray-800;
      background: linear-gradient(135deg, #a78bfa10, #c4b5fd20, #ddd6fe10, #ede9fe10) fixed;
      background-size: 400% 400%;
      animation: gradient 15s ease infinite;
      position: relative;
    }
    
    body::after {
      content: '';
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      background-image: url("data:image/svg+xml,%3Csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3Cpattern id='mesh' width='20' height='20' patternUnits='userSpaceOnUse'%3E%3Cpath d='M 0 10 L 10 0 L 20 10 L 10 20 Z' fill='none' stroke='%23a78bfa10' stroke-width='1'/%3E%3C/pattern%3E%3C/defs%3E%3Crect width='100%25' height='100%25' fill='url(%23mesh)'/%3E%3C/svg%3E");
    }
  }
  
  /* カードのホバーエフェクト */
  @layer components {
    .card-hover {
      @apply transition-all duration-300 ease-in-out;
    }
    
    .card-hover:hover {
      @apply transform -translate-y-1 shadow-xl;
    }
    
    /* ボタンスタイル */
    .social-button {
      @apply flex items-center justify-center px-4 py-2 rounded-lg transition-all duration-300 hover:scale-105;
    }
    
    /* アバター画像のアニメーション */
    .avatar-animation {
      @apply transition-transform duration-300 hover:scale-110;
    }
    
    /* スクロールスナップ */
    .scroll-snap-container {
      scroll-snap-type: y mandatory;
    }
    
    .scroll-snap-section {
      scroll-snap-align: start;
    }
    
    /* イメージギャラリー */
    .gallery-image {
      @apply overflow-hidden rounded-lg shadow-lg;
      transition: transform 0.3s ease, box-shadow 0.3s ease;
    }
    
    .gallery-image:hover {
      @apply transform scale-105 shadow-xl;
    }
    
    /* 美しいガラスモーフィズム効果 */
    .glass-effect {
      background: rgba(255, 255, 255, 0.25);
      backdrop-filter: blur(8px);
      -webkit-backdrop-filter: blur(8px);
      border: 1px solid rgba(255, 255, 255, 0.18);
      box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.1);
    }
  }
  
  /* ページ遷移アニメーション */
  .page-enter {
    opacity: 0;
    transform: translateY(20px);
  }
  
  .page-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 500ms, transform 500ms;
  }
  
  .page-exit {
    opacity: 1;
    transform: translateY(0);
  }
  
  .page-exit-active {
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity 500ms, transform 500ms;
  }